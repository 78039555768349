<template>
	<div>
		<div class="top"><div class="title">PLUS会员</div></div>
		<div class="wrap">
			<div class="wrap-left">
				<div>
					<i class="iconfont icontouxiang" v-if="!userpic"></i>
					<img class="userpic" :src="userpic" v-if="userpic" alt="头像" />
					<img v-if="user.IsVip == 1" class="img" src="../../assets/plus.png" alt="" />
				</div>
				<div class="name">
					<div class="name-top">
						<div>{{ user.UserName }}</div>
						<div><img src="../../assets/plus-icon3.png" class="vip-icon" alt="" /></div>
						<div class="year">年卡会员</div>
					</div>
					<div class="data" v-if="user.IsVip == 1">{{ user.VipTime }} 到期</div>
					<div class="record" @click="record" style="color:#0997BB">购买记录 ></div>
				</div>
				<div class="immediately" @click="toPay">
					<span v-if="user.IsVip == 1">立即续费</span>
					<span v-else>立即购买</span>
				</div>
			</div>
			<div class="wrap-right">
				<div><img src="../../assets/plus-icon.png" alt="" /></div>
				<div class="wrap-right-text">
					<div class="text1">PLUS会员年卡</div>
					<div class="text1">￥{{ price.Price }}/年</div>
					<div class="text3">￥{{ price.OldPrice }}</div>
				</div>
			</div>
		</div>
		<div class="privilege">
			<div class="title">会员权益</div>
			<div class="privilege-list">
				<div>
					<div><img src="../../assets/plus-icon1.png" alt="" /></div>
					<div class="text">2倍积分返利</div>
				</div>
				<div>
					<div><img src="../../assets/plus-icon1.png" alt="" /></div>
					<div class="text">PLUS会员价</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getUserInfo, getPlusPrice } from './service';

export default {
	watch: {
		isLogin: {
			handler(n) {
				if (localStorage.getItem('userToken')) {
					this.$store.commit('setLogin', true);
				} else {
					this.$store.commit('setLogin', false);
				}
				this.init();
			},
			// deep: true,
			immediate: true
		}
	},
	data() {
		return {
			user: false,
			userpic: null,
			price: []
		};
	},
	mounted() {},
	methods: {
		record() {
			window.open('/me/record', '_blank').location;
		},
		toplus() {
			if (!localStorage.getItem('userToken')) {
				this.toCIAMLoginIn();
				// localStorage.setItem('u', '')
				//          this.$message.success('正在跳转登录')
				//          setTimeout(() => {
				//              let url = encodeURI(window.location.href)
				//              window.location.href = `${URL_LOGIN}?returnurl=${url}`
				//          }, 1000)
			} else {
				window.open(`/me/plus`, '_blank').location;
			}
		},
		init() {
			// 获取用户信息
			getUserInfo().then(res => {
				this.user = res.data;
				this.userpic = res.data.Pictrue;
			});
			// 获取用户信息
			getPlusPrice().then(res => {
				this.price = res.data;
			});
		},
		toPay() {
			window.open('/pluspay', '_blank').location;
		}
	}
};
</script>

<style lang="less" scoped>
.userpic {
	width: 100px;
	border-radius: 50%;
}
.wrap-right {
	background: #2d2d2d;
	width: 50%;
	height: 110px;
	padding: 30px;
	display: flex;
	color: #ffffff;
	img {
		width: 120px;
	}
	.wrap-right-text {
		text-align: center;
		margin-left: 50px;
		.text1 {
			font-family: 'ct';
			font-size: 24px;
		}
		.text3 {
			margin-top: 10px;
			text-decoration: line-through;
			color: #999999;
		}
	}
}
.vip {
	font-family: '微软雅黑';
	font-size: 16px;
	color: #f8b62d;
}
.data {
	font-size: 14px;
	color: #666666;
	margin: 5px 0;
}
.record {
	font-size: 14px;
	cursor: pointer;
}
.img {
	position: absolute;
	bottom: 41px;
	left: 59px;
}
.top {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #dcdcdc;
	padding-bottom: 5px;

	.title {
		font-size: 18px;
		font-family: 'ct';
	}
}
.privilege {
	margin-top: 50px;
	.title {
		font-size: 18px;
		font-family: 'ct';
	}
	.privilege-list {
		display: flex;
		margin-top: 20px;
		div {
			margin-right: 20px;
		}
		.text {
			margin-top: 10px;
		}
	}
}
.vip-icon {
	width: 20px;
	height: 20px;
	margin-left: 15px;
}
.wrap {
	margin-top: 20px;
	display: flex;
	.wrap-left {
		width: 50%;
		height: 110px;
		background: #f4f5fa;
		padding: 30px;
		display: flex;
		position: relative;
		overflow: hidden;
		i {
			color: #cecece;
			font-size: 100px;
		}
		.name {
			font-size: 22px;
			color: #666666;
			margin-left: 10px;
		}
		.name-top {
			display: flex;
			.year {
				color: #f3961a;
				font-size: 14px;
				margin-left: 5px;
				margin-top: 6px;
			}
		}
		.immediately {
			background: #f3961a;
			color: #ffffff;
			width: 170px;
			height: 40px;
			border-radius: 50px;
			text-align: center;
			line-height: 40px;
			font-size: 18px;
			position: absolute;
			bottom: 10px;
			right: -20px;
			cursor: pointer;
		}
	}
}
</style>
