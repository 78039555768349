import { post } from '@/http'

// 获取用户基本信息
export function getUserInfo(data) {
    return new Promise((resolve, reject) => {
        post('/Users/GetUserInfo', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 获取PLUS会员价格
export function getPlusPrice(data) {
    return new Promise((resolve, reject) => {
        post('/PlusOrder/GetPlusPrice', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
